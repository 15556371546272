@custom-media --viewport-s (min-width: 480px);
@custom-media --viewport-m (min-width: 768px);
@custom-media --viewport-l (min-width: 960px);

:root {
  --color-gray-text: #222222;
  --color-gray-text-light: #666666;
  --color-gray-silver: #cccccc;
  --color-gray-silver-light: #e6e6e6;
  --color-gray-silver-dark: #aaaaaa;
  --color-gray-silver-background: #f7f7f7;
  --color-white: #ffffff;

  --color-editor-blue: #0000ff;
  --color-editor-blue-hover: #00008c;
  --color-editor-shade: #403f4c;
  --color-editor-shade-dark: #28272f;

  --color-green: #2c9d78;
  --color-red: #e7312b;

  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 21px;
  --font-size-xxl: 25px;
  --font-size-xxxl: 31px;
  --font-size-xxxxl: 49px;

  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  --line-height-xs: 1;
  --line-height-s: 1.2;
  --line-height-m: 1.5;
  --line-height-l: 1.75;

  --z-index-editBar: 100;
  --z-index-sidebar-overlay: 800;
  --z-index-sidebar: 900;
  --z-index-menu-dropdown: 1000;
  --z-index-header: 1000;
  --z-index-overlay: 1000000;
  --z-index-notification: 100000000;
  --z-index-menu: 1000000000;
  --z-index-loader: 10000000000;

  --border-radius: 5px;

  --sidebar-width: 300px;
}

::-moz-selection {
  background: var(--color-editor-blue);
  color: var(--color-white);
}

::selection {
  background: var(--color-editor-blue);
  color: var(--color-white);
}

html {
  box-sizing: border-box;
  font-size: var(--font-size-m);
}

*,
*:before,
*:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
}

body {
  background-color: var(--color-white);
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

button {
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  background: transparent;
  border: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}
